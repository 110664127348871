import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust this path as necessary

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: #0A2F53;
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
`;

const ResultsList = styled.ul`
  list-style: none;
  padding: 0;
`;

const ResultItem = styled.li`
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ResultDetails = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const FixtureTeams = styled.span`
  font-weight: bold;
  color: #0A2F53;
  margin-bottom: 5px;
  @media (min-width: 600px) {
    margin-bottom: 0;
  }
`;

const FilterContainer = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

const GameweekLabel = styled.span`
  font-weight: bold;
  color: #37003c;
  margin-right: 10px;
`;

function Results() {
  const [results, setResults] = useState([]);
  const [selectedCupGameweek, setSelectedCupGameweek] = useState(1); // Start from Cup Gameweek 1

  useEffect(() => {
    const fixturesQuery = query(
      collection(db, 'fixtures'),
      orderBy('gameweek', 'asc'),
      orderBy('fixture_date', 'asc')
    );

    const unsubscribe = onSnapshot(fixturesQuery, (querySnapshot) => {
      if (querySnapshot.empty) {
        console.log('No fixtures found.');
        return;
      }

      const fixturesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      console.log('Fetched Fixtures:', fixturesList);

      // Group fixtures into Cup Gameweeks
      const groupedFixtures = {};
      let currentCupGameweek = 1; // Start from Cup Gameweek 1

      fixturesList.forEach((fixture) => {
        if (currentCupGameweek > 9) return; // Stop if we already filled all 9 cup gameweeks

        if (!groupedFixtures[currentCupGameweek]) {
          groupedFixtures[currentCupGameweek] = [];
        }

        if (groupedFixtures[currentCupGameweek].length < 5) {
          groupedFixtures[currentCupGameweek].push(fixture);
        }

        if (groupedFixtures[currentCupGameweek].length === 5) {
          currentCupGameweek++;
        }
      });

      // Ensure all Cup Gameweeks exist, even if empty
      for (let i = 1; i <= 9; i++) { // Start from Cup Gameweek 1
        if (!groupedFixtures[i]) {
          groupedFixtures[i] = [];
        }
      }

      console.log('Grouped Fixtures:', groupedFixtures);

      setResults(groupedFixtures);
    });

    return () => unsubscribe();
  }, []);

  const handleCupGameweekChange = (e) => {
    setSelectedCupGameweek(Number(e.target.value));
  };

  return (
    <Container>
      <Title>Results</Title>

      {/* Gameweek Filter */}
      <FilterContainer>
        <label htmlFor="cupGameweek">Select Cup Gameweek: </label>
        <select id="cupGameweek" value={selectedCupGameweek} onChange={handleCupGameweekChange}>
          {[...Array(9)].map((_, i) => ( // Start from Cup Gameweek 1
            <option key={i+1} value={i+1}>Cup Gameweek {i+1}</option>
          ))}
        </select>
      </FilterContainer>

      {/* Results List */}
      <ResultsList>
        {results[selectedCupGameweek]?.map((fixture) => (
          <ResultItem key={fixture.id}>
            <ResultDetails>
              <GameweekLabel>GW {fixture.gameweek}</GameweekLabel>
              <FixtureTeams>{fixture.home_team} vs {fixture.away_team}</FixtureTeams>
              {fixture.result && (
                <span>{fixture.result}</span>
              )}
            </ResultDetails>
          </ResultItem>
        ))}
      </ResultsList>
    </Container>
  );
}

export default Results;
