import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust this path as necessary

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: #0A2F53;
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
`;

const FixtureList = styled.ul`
  list-style: none;
  padding: 0;
`;

const FixtureItem = styled.li`
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FixtureDetails = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const FixtureTeams = styled.span`
  font-weight: bold;
  color: #0A2F53;
  margin-bottom: 5px;
  @media (min-width: 600px) {
    margin-bottom: 0;
  }
`;

const FixtureDate = styled.span`
  color: #888888;
  margin-bottom: 5px;
  @media (min-width: 600px) {
    margin-bottom: 0;
  }
`;

const FilterContainer = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

const GameweekLabel = styled.span`
  font-weight: bold;
  color: #37003c;
  margin-right: 10px;
`;

const formatDateTime = (timestamp) => {
  const date = new Date(timestamp.seconds * 1000);
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return `${monthNames[date.getMonth()]} ${date.getDate()} at ${date.getHours() % 12 || 12}${date.getHours() >= 12 ? 'PM' : 'AM'}`;
};

function Fixtures() {
  const [cupGameweeks, setCupGameweeks] = useState({});
  const [selectedCupGameweek, setSelectedCupGameweek] = useState(2); // Start from Cup Gameweek 2

  useEffect(() => {
    const fixturesQuery = query(
      collection(db, 'fixtures'),
      orderBy('gameweek', 'asc'),
      orderBy('fixture_date', 'asc')
    );

    const unsubscribe = onSnapshot(fixturesQuery, (querySnapshot) => {
      if (querySnapshot.empty) {
        console.log('No fixtures found.');
        return;
      }

      const fixturesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      console.log('Fetched Fixtures:', fixturesList);

      // Filter out fixtures for gameweeks less than 6
      const filteredFixturesList = fixturesList.filter(fixture => fixture.gameweek >= 6);

      // Group fixtures into Cup Gameweeks
      const groupedFixtures = {};
      let currentCupGameweek = 2; // Start from Cup Gameweek 2

      filteredFixturesList.forEach((fixture) => {
        if (currentCupGameweek > 9) return; // Stop if we already filled all 9 cup gameweeks

        if (!groupedFixtures[currentCupGameweek]) {
          groupedFixtures[currentCupGameweek] = [];
        }

        if (groupedFixtures[currentCupGameweek].length < 5) {
          groupedFixtures[currentCupGameweek].push(fixture);
        }

        if (groupedFixtures[currentCupGameweek].length === 5) {
          currentCupGameweek++;
        }
      });

      // Ensure all Cup Gameweeks exist, even if empty
      for (let i = 2; i <= 9; i++) { // Start from Cup Gameweek 2
        if (!groupedFixtures[i]) {
          groupedFixtures[i] = [];
        }
      }

      console.log('Grouped Fixtures:', groupedFixtures);

      setCupGameweeks(groupedFixtures);
    });

    return () => unsubscribe();
  }, []);

  const handleCupGameweekChange = (e) => {
    setSelectedCupGameweek(Number(e.target.value));
  };

  return (
    <Container>
      <Title>Upcoming Fixtures</Title>

      <FilterContainer>
        <label htmlFor="cupGameweek">Select Cup Gameweek: </label>
        <select id="cupGameweek" value={selectedCupGameweek} onChange={handleCupGameweekChange}>
          {[...Array(8)].map((_, i) => ( // Start from Cup Gameweek 2
            <option key={i+2} value={i+2}>Cup Gameweek {i+2}</option>
          ))}
        </select>
      </FilterContainer>

      <FixtureList>
        {cupGameweeks[selectedCupGameweek]?.map((fixture) => (
          <FixtureItem key={fixture.id}>
            <FixtureDetails>
              <GameweekLabel>GW {fixture.gameweek}</GameweekLabel>
              <FixtureTeams>{fixture.home_team} vs {fixture.away_team}</FixtureTeams>
              <FixtureDate>
                {formatDateTime(fixture.fixture_date)}
              </FixtureDate>
            </FixtureDetails>
          </FixtureItem>
        ))}
      </FixtureList>
    </Container>
  );
}

export default Fixtures;
