import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCalendarAlt, faTrophy } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import premierLeagueLogo from '../assets/premier-league-logo.png';

const NavbarContainer = styled.nav`
  background-color: #37003c;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled.div`
  background-color: #37003c;
  display: flex;
  align-items: center;
  height: 40px;
`;

const Logo = styled.img`
  height: 100%;
  width: auto;
  object-fit: contain;
`;

const NavList = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
`;

const NavItem = styled.li`
  margin-left: 20px;
`;

const NavLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;

  &:hover {
    color: #00ff87;
  }
`;

const NavIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  margin-bottom: 5px;
`;

function NavBar() {
  return (
    <NavbarContainer>
      <LogoContainer>
        <Logo src={premierLeagueLogo} alt="Premier League Logo" />
      </LogoContainer>
      <NavList>
        <NavItem>
          <NavLink to="/">
            <NavIcon icon={faHome} />
            <span>Home</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/fixtures">
            <NavIcon icon={faCalendarAlt} />
            <span>Fixtures</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/results">
            <NavIcon icon={faTrophy} />
            <span>Results</span>
          </NavLink>
        </NavItem>
      </NavList>
    </NavbarContainer>
  );
}

export default NavBar;