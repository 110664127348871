import React from 'react';
import styled from 'styled-components';

const BannerContainer = styled.div`
  background: linear-gradient(90deg, #37003c 0%, #963cff 100%);
  display: flex;
  align-items: center;
  padding: 10px 20px;
  height: 60px;
`;

const BannerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const BannerTitle = styled.h1`
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
`;

function HomeBanner() {
  return (
    <BannerContainer>
      <BannerContent>
        <BannerTitle>FPL Draft Cup</BannerTitle>
      </BannerContent>
    </BannerContainer>
  );
}

export default HomeBanner;